/* eslint-disable max-lines-per-function */
import { Header } from 'featured-pages/components/header'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { FeaturedBoxes } from 'featured-pages/components/featured-boxes'
import { boxes } from 'featured-pages/create/boxes'
import visual0 from 'startup/assets/featured-page-visual-3-1.png'
import visual1 from 'startup/assets/featured-page-visual-3-2.png'
import visual2 from 'startup/assets/featured-page-visual-3-3.png'
import { FeaturedTwitter } from 'components/featured-twitter'

const visuals = [
  { img: visual0, width: '384px', top: '40%' },
  { img: visual1, width: '444px', top: '80%' },
  { img: visual2, width: '203px', top: '90%', left: '30%' },
]

export const Create = () => <>
  <ScrollToTopOnMount />
  <Header label={'Introducing the'} visuals={visuals}>
    <h1>Lighthouse Series</h1>
    <p>
      As we weather the present storm, our goal is more important than ever: be the beacon
      of light for the ecosystem, provide clarity during these uncertain times, offer helpful
      resources, sage advice, and unwavering support. All with the aim of navigating the storm,
      and emerging more fortified on the other side.
    </p>
  </Header>
  <FeaturedBoxes boxes={boxes} />
  <FeaturedTwitter />
</>
