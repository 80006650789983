import { useDispatch } from 'react-redux'
import { useEndCursor } from 'subdomain/modals/account/my-offers/hooks/use-end-cursor'
import { useCallback } from 'react'
import { actions } from 'subdomain/modals/account/my-offers/slices/get'
import { payload } from 'subdomain/modals/account/my-offers/payload'
import { useCmsMarketplaceKey } from 'subdomain/cms-content/hooks/use-cms-marketplace-key'
import { useFirstAcceptedPaymentToken } from 'marketplaces/hooks/use-first-accepted-payment-token'
import { get } from 'lodash'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

const useCursor = customCursor => {
  const endCursor = useEndCursor()

  return customCursor === undefined ? endCursor : customCursor
}

export const useNext = customCursor => {
  const dispatch = useDispatch()
  const cursor = useCursor(customCursor)
  const marketplaceKey = useCmsMarketplaceKey()
  const acceptedToken = useFirstAcceptedPaymentToken()
  const priceToken = get(acceptedToken, 'identifier')
  const { address } = useGetAccountInfo()

  return useCallback(() => {
    marketplaceKey && address && dispatch(actions.attempt(payload({ cursor, marketplaceKey, priceToken, address })))
  }, [marketplaceKey, dispatch, cursor, priceToken, address])
}
