import { Label } from 'filters/components/dropdown-filter/label'
import { Dropdown } from 'components/dropdown'
import { FlexRow } from 'components/flex-row'

export const DropdownFilter = ({ name, label, options, id, onChange = () => {}, className = '', disabled }) => (
  <FlexRow className={`dropdown-filter align-items-center ${className}`}>
    <Label label={label} />
    <Dropdown id={id} onChange={onChange} options={options} disabled={disabled} name={name} />
  </FlexRow>
)
