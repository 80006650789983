export const menuLinks = [
  {
    label: 'Home',
    path: '/',
    authenticated: true,
  },
  {
    label: 'Feed',
    path: '/feed',
    authenticated: false,
  },
  {
    label: 'Explore',
    path: '/nfts',
    authenticated: true,
  },
]
