import { useDispatch } from 'react-redux'
import { useFetchPayload } from 'explore-nfts/tabs/creators/hooks/use-fetch-payload'
import { useIsPrinceCharming } from 'auth/mega-authorized'
import { useCallback } from 'react'
import { actions } from 'explore-nfts/tabs/creators/slices/get'

export const useIssueServer = customCursor => {
  const dispatch = useDispatch()
  const payload = useFetchPayload(customCursor)
  const isLoggedIn = useIsPrinceCharming()

  return useCallback(() => {
    dispatch(actions.attempt({ ...payload, isLoggedIn }))
  }, [dispatch, isLoggedIn, payload])
}
