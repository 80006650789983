import { from, map, of } from 'rxjs'
import { logout } from 'network/logout'
import { catchError, mergeMap } from 'rxjs/operators'
import { settings } from 'settings/fetching'
import { getAddress } from '@elrondnetwork/dapp-core/utils'
import { getNativeAuthToken } from 'network/get-native-auth-token'

const makeUrl = () => `${settings().maiarId}/api/v1`

const tokenPayload = async() => ({
  address: await getAddress(),
  maiarIdApi: makeUrl(),
})

const graphPayload = (payload, accessToken) => ({
  ...payload,
  context: { headers: { authorization: `Bearer ${accessToken}` } },
})

const makePayload = payload => from(tokenPayload())
  .pipe(mergeMap(tokenPayload => of(getNativeAuthToken())))
  .pipe(map(accessToken => graphPayload(payload, accessToken)))
  .pipe(catchError(e => logout()))

export const auth = { makePayload }
