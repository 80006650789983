import React, { useMemo } from 'react'
import * as Core from 'components/player'
import { PlayerControls } from 'components/player-controls'
import { usePlayerSource } from 'nft-page/media/hooks/use-player-source'
import { useParams } from 'react-router-dom'
import './index.scss'

const DEFAULT_OPTIONS = { muted: true, controls: false, autoplay: true }

export const Player = ({ player }) => {
  const { identifier } = useParams()
  const sources = usePlayerSource()
  const handlePlayPause = PlayerControls.useToggleCallback(player)

  return useMemo(() => <div className={'nft-video'}>
    <div className={'nft-video-player'} onClick={handlePlayPause}
         onTouchEnd={handlePlayPause}>
      <Core.Player sources={[sources]} options={DEFAULT_OPTIONS} instance={player} />
    </div>
    {/* eslint-disable-next-line */}
  </div>, [handlePlayPause, player, sources, identifier])
}

Player.displayName = 'Player'
