import grid from 'startup/assets/icon-grid.svg'
import physics from 'startup/assets/icon-physics.svg'
import lighthouse from 'startup/assets/icon-lighthouse.svg'
import chart from 'startup/assets/icon-chart-grow.svg'

export const boxes = [
  {
    icon: grid,
    title: 'Clarity on the ecosystem',
    subtitle: <>
      Understand trends, challenges, opportunities, and key players in the ecosystem to make
      effective strategic decisions.
    </>,
  },
  {
    icon: physics,
    title: 'The power of community',
    subtitle: <>
      Belong to a community that shares similar values, goals and challenges to provide connection,
      support, inspiration and motivation.
    </>,
  },
  {
    icon: lighthouse,
    title: 'Beacon of light',
    subtitle: <>
      Study the successful use cases, projects, and creators for insights, inspiration, and
      understanding of what worked and what didn&apos;t.
    </>,
  },
  {
    icon: chart,
    title: 'Opportunities for growth',
    subtitle: <>
      Network and collaborate with others to gain new perspectives, partnerships and business
      opportunities, and stay competitive and evolve.
    </>,
  },
]
