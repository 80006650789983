/* eslint-disable max-lines-per-function */
import { Header } from 'featured-pages/components/header'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { FeaturedBoxes } from 'featured-pages/components/featured-boxes'
import { boxes } from 'featured-pages/learn/boxes'
import visual0 from 'startup/assets/featured-page-visual-2-1.png'
import visual1 from 'startup/assets/featured-page-visual-2-2.png'
import visual2 from 'startup/assets/featured-page-visual-2-3.png'
import { FeaturedTwitter } from 'components/featured-twitter'

const visuals = [
  { img: visual0, width: '562px', top: '30%', left: 0 },
  { img: visual1, width: '421px', top: '60%', left: '100%' },
  { img: visual2, width: '262px', top: '90%', left: '30%' },
]

export const Learn = () => <>
  <ScrollToTopOnMount />
  <Header label={'New to it all?'} visuals={visuals}>
    <h1>Understanding Web3</h1>
    <p>
      You have no doubt heard the term web3 thrown around a lot lately, but do you actually
      understand what it means? Or maybe you just need a refresher on its principal building
      blocks. Have no fear, we got you covered.
    </p>
  </Header>
  <FeaturedBoxes boxes={boxes} />
  <FeaturedTwitter />
</>

