import image from 'startup/assets/learn-image.svg'
import { Button } from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

export const Learn = () => <div className={'col-12 col-lg-4 d-flex mb-4 mb-md-4'}>
  <div className={'p-4 home-welcome-background '}>
    <img src={image} alt={'...'} className={'home-welcome-image'} />
    <p className={'home-welcome-title'}>Understanding Web3</p>
    <p className={'home-welcome-description'}>
      You have no doubt heard the term web3 thrown around a lot lately,
      but do you actually understand what it means?...
    </p>
    <Button to={'/learn'} buttonType={'secondary'} className={'home-welcome-button'}>
      Read More
      {' '}
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  </div>
</div>
