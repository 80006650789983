import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from 'home/featured-collections/slices'

export const useFetch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.attempt({ filters: { type: 'Featured' }, pagination: { first: 3 } }))
  }, [dispatch])
}
