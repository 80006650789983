/* eslint-disable max-lines-per-function */
import { Header } from 'featured-pages/components/header'
import { ScrollToTopOnMount } from 'components/scroll-top-on-mount'
import { FeaturedBoxes } from 'featured-pages/components/featured-boxes'
import { boxes } from 'featured-pages/kickstart/boxes'
import visual0 from 'startup/assets/featured-page-visual-1-1.png'
import visual1 from 'startup/assets/featured-page-visual-1-2.png'
import visual2 from 'startup/assets/featured-page-visual-1-3.png'
import { FeaturedTwitter } from 'components/featured-twitter'

const visuals = [
  { img: visual0, width: '663px', top: '70%' },
  { img: visual1, width: '401px', top: '10%' },
  { img: visual2, width: '335px', top: '90%' },
]

export const Kickstart = () => <div className={'kickstart featured-page'}>
  <ScrollToTopOnMount />
  <Header label={'What\'s New?'} visuals={visuals}>
    <h1>Welcome to xSpotlight!</h1>
    <p>
      Here you will find amazing content from across the MultiversX, created by people all over
      the world pushing the boundaries of creativity and technology. Our spotlight shines a light
      on all these amazing creators and projects that deserve our praise and recognition.
    </p>
  </Header>
  <FeaturedBoxes boxes={boxes} />
  <FeaturedTwitter />
</div>
