import { gql } from 'graphql-tag'

export const GET_SELECTED_AUCTION = gql`
    query selectedAuction($sorting: [Sorting!], $filters: FiltersExpression, $grouping: Grouping, $pagination: ConnectionArgs) {
        auctions(sorting: $sorting, filters: $filters, grouping: $grouping, pagination: $pagination)
        {
            edges {
                node {
                    asset{
                        attributes
                        balance
                        collection
                        creationDate
                        artist{
                            address
                            profile
                            herotag
                        }
                        identifier
                        metadata{
                            description
                        }
                        name
                        ownerAddress
                        owner{
                            address
                            profile
                            herotag
                        }
                        royalties
                        tags
                        media{
                            thumbnailUrl
                            fileType
                            url
                        }
                        type
                        uris
                        isNsfw
                        verified
                    }
                    availableTokens
                    collection
                    endDate
                    id
                    identifier
                    marketplaceKey
                    marketplace{
                        name
                        url
                    }
                    maxBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    minBidDiff{
                        amount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    nrAuctionedTokens
                    orders{
                        edges{
                            cursor
                        }
                    }
                    ownerAddress
                    owner{
                        address
                        profile
                        herotag
                    }
                    startDate
                    status
                    tags
                    topBid {
                        amount
                        token
                        usdAmount
                        tokenData{
                            decimals
                            symbol
                        }
                    }
                    topBidder{
                        address
                        profile
                        herotag
                    }
                    type
                }
                cursor
            }
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            pageData{
                count
            }
        }
    }
`
