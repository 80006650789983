import { get } from 'lodash'
import { getThumbnailUrl } from 'common/asset/get-thumbnail-url'
import DefaultThumbnail from 'startup/assets/nft-image-placeholder.png'

export const useSource = props => {
  const asset = get(props, 'asset', {})
  const scamInfo = get(asset, 'scamInfo', '')
  const isNsfw = get(asset, 'isNsfw', false)
  const thumbnail = getThumbnailUrl(asset)

  return scamInfo || isNsfw ? DefaultThumbnail : thumbnail
}
