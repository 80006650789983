import { get } from 'lodash'
import { VerifiedBadge } from 'components/verified-badge/index'
import './title.scss'

const getScamTitle = scamInfo => {
  const type = get(scamInfo, 'type', '')
  const info = get(scamInfo, 'info', '')

  return type === 'potentialScam' ? `Potential Scam - ${info}` : `Scam - ${info}`
}

export const Title = ({ asset }) => {
  const assetName = get(asset, 'name', '')
  const scamInfo = get(asset, 'scamInfo', '')
  const verified = get(asset, 'verified')

  return <div className={'asset-card-title-col col-12 d-flex'}>
    <div className={'asset-card-title'}>{scamInfo ? getScamTitle(scamInfo) : assetName}</div>
    {verified && <VerifiedBadge />}
  </div>
}

