import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useCallback } from 'react'
import { withdraw } from 'offers/slices'

export const useWithdrawOffer = offerId => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()

  return useCallback(() => {
    address && dispatch(withdraw.actions.attempt({ offerId }))
  }, [address, dispatch, offerId])
}
