import { Background } from 'home/cta-section/background'
import { Box } from 'home/cta-section/box'
import { Subtitle } from 'home/cta-section/subtitle'
import { Title } from 'home/cta-section/title'
import 'home/cta-section/index.scss'

const boxes = [
  {
    title: 'Explore',
    subtitle: 'Discover the best, go beyond the surface, learn about what matters.',
  },
  {
    title: 'Collect',
    subtitle: 'Choose favorite creators and projects, make them part of your collection.',
  },
  {
    title: 'Engage',
    subtitle: 'Get involved with the community, represent the projects you believe in.',
  },
]

export const CTASection = () => <div className={'cta-section'}>
  <Background />
  <div className={'container'}>
    <Title />
    <Subtitle />
    <div className={'cta-section-boxes row'}>
      {boxes.map((props, index) => <div key={index} className={'col-12 col-sm-12 col-md-4 col-lg-4 d-flex'}>
        <Box {...props} />
      </div>)}
    </div>
  </div>
</div>
