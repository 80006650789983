import { Terms } from 'terms'
import { Logout } from 'logout'
import { HomePage } from 'home'
import { Privacy } from 'privacy'
import { Account } from 'account'
import { NftPage } from 'nft-page'
import { Ledger } from 'dapp/ledger'
import { Trending } from 'trending'
import { Collections } from 'collections'
import { ExploreNFTs } from 'explore-nfts'
import { WalletConnect } from 'dapp/wallet-connect'
import { NoSearchResults } from 'search/no-search-results'
import { HomePageRedirect } from 'startup/home-page-redirect'
// TODO: Merge these imports
import { Auction } from 'modals/auction'
import { Account as AccountModals } from 'modals/account'
import { CollectionModals } from 'modals/collection'
import { Feed } from 'feed'
import { Campaign } from 'campaign'
import { Campaign as CampaignModals } from 'modals/campaign'
import { Campaigns } from 'campaigns'
import { Kickstart } from 'featured-pages/kickstart'
import { Learn } from 'featured-pages/learn'
import { Create } from 'featured-pages/create'
import { Master } from 'featured-pages/master'
import { HomePage as HomePageSubdomain } from 'subdomain/home'
import { Account as SubdomainAccountModals } from 'subdomain/modals/account'
import { HolorideCampaign } from 'holoride-campaign'
import { HoloModals } from 'holoride-campaign/modals'

const env = () => {
  const env = process.env.REACT_APP_NODE_ENV
  const subdomain = process.env.REACT_APP_NODE_SUBDOMAIN

  return subdomain || env
}

const allRouteNames = {
  home: '/',
  dashboard: '/account',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  nftPage: '/nfts/:identifier',
  nftPageModal: '/nfts/:identifier/modal/:modalName',
  collectionsPageModal: '/nfts/:identifier/modal/:modalName',
  editProfile: '/:address/modal/editProfile',
  accountModalStep: '/:address/modal/:modalName/:step',
  accountModal: '/:address/modal/:modalName',
  account: '/:address/:tab',
  exploreNfts: '/explore-nfts',
  market: '/market',
  trending: '/trending',
  feed: '/feed',
  logout: '/logout',
  failure: '/failure',
  search: '/search',
  terms: '/terms',
  privacy: '/privacy',
  kickstart: '/kickstart',
  learn: '/learn',
  create: '/create',
  master: '/master',
}

const subdomainRouteNames = {
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  nftPage: '/nfts/:identifier',
  nftPageWithAuction: '/nfts/:identifier/auction/:auctionId',
  nftPageModal: '/nfts/:identifier/modal/:modalName',
  collectionsPageModal: '/nfts/:identifier/modal/:modalName',
  logout: '/logout',
  failure: '/failure',
}

export const routeNames = env() === 'subdomain' ? subdomainRouteNames : allRouteNames

const allRoutes = [
  {
    path: routeNames.privacy,
    title: 'Privacy Policy',
    exact: true,
    footer: true,
    navigation: true,
    element: Privacy,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: routeNames.terms,
    title: 'Terms and Conditions',
    exact: true,
    footer: true,
    navigation: true,
    element: Terms,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: routeNames.kickstart,
    title: 'Kickstart',
    exact: true,
    footer: true,
    navigation: true,
    element: Kickstart,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: routeNames.learn,
    title: 'Learn',
    exact: true,
    footer: true,
    navigation: true,
    element: Learn,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: routeNames.create,
    title: 'Create',
    exact: true,
    footer: true,
    navigation: true,
    element: Create,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: routeNames.master,
    title: 'Master',
    exact: true,
    footer: true,
    navigation: true,
    element: Master,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/search/:searchPhrase',
    title: 'No results',
    exact: true,
    footer: true,
    navigation: true,
    element: NoSearchResults,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/collections/:identifier/modal/:modalName',
    title: 'Collections',
    exact: true,
    footer: true,
    navigation: true,
    element: CollectionModals,
    authenticatedRoute: true,
    redirectPath: '/collections/:identifier',
    layered: true,
    adminSidebar: { actions: ['markCollectionNsfw'] },
  },
  {
    path: '/collections/:identifier',
    title: 'Collection',
    exact: true,
    footer: true,
    navigation: true,
    element: Collections,
    authenticatedRoute: false,
    layered: false,
    adminSidebar: { actions: ['markCollectionNsfw'] },
  },

  {
    path: '/ledger',
    title: 'Ledger',
    footer: true,
    navigation: true,
    element: Ledger,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/walletconnect',
    title: 'WalletConnect',
    footer: true,
    navigation: true,
    element: WalletConnect,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/collections',
    title: 'ExploreNFTs',
    exact: true,
    footer: true,
    navigation: true,
    element: ExploreNFTs,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/creators',
    title: 'ExploreNFTs',
    exact: true,
    footer: true,
    navigation: true,
    element: ExploreNFTs,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/trending',
    title: 'Hottest',
    exact: true,
    footer: true,
    navigation: true,
    element: Trending,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/nfts/:identifier/auction/:auctionId',
    title: 'TokenIdentifier',
    exact: true,
    footer: false,
    navigation: true,
    element: NftPage,
    authenticatedRoute: false,
    sidebarOnTop: true,
    bodyOverflow: true,
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
  },
  {
    path: '/nfts/:identifier',
    title: 'TokenIdentifier',
    exact: false,
    footer: false,
    navigation: true,
    element: NftPage,
    authenticatedRoute: false,
    sidebarOnTop: true,
    bodyOverflow: true,
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
  },
  {
    path: '/nfts',
    title: 'ExploreNFTs',
    exact: true,
    footer: true,
    navigation: true,
    element: ExploreNFTs,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/feed',
    title: 'Feed',
    exact: true,
    element: Feed,
    navigation: true,
  },
  {
    path: '/campaigns',
    title: 'Campaigns',
    exact: true,
    footer: false,
    element: Campaigns,
    navigation: true,
  },
  {
    path: '/campaigns/:minterAddress/:campaignId',
    title: 'Campaign',
    exact: true,
    element: Campaign,
    navigation: true,
  },
  {
    path: '/campaigns/:minterAddress/:campaignId/:tier/modal/:modalName',
    title: 'Campaign',
    exact: true,
    element: CampaignModals,
    authenticatedRoute: true,
    redirectPath: '/campaigns/:minterAddress/:campaignId',
    navigation: true,
    preventDirectNavigation: true,
  },
  {
    path: '/logout',
    title: 'Logout',
    exact: true,
    footer: true,
    navigation: true,
    element: Logout,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/:address/modal/:modalName/:step',
    title: 'Account',
    footer: true,
    navigation: true,
    element: AccountModals,
    exact: true,
    authenticatedRoute: true,
    redirectPath: '/:address',
    layered: true,
  },
  {
    path: '/:address/modal/:modalName',
    title: 'Account',
    footer: true,
    navigation: true,
    element: AccountModals,
    exact: true,
    authenticatedRoute: true,
    redirectPath: '/:address',
    layered: true,
  },
  {
    path: '/:address/:tab',
    title: 'Account',
    exact: true,
    footer: true,
    navigation: true,
    element: Account,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/:address',
    title: 'Account',
    exact: true,
    footer: true,
    navigation: true,
    element: Account,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/',
    title: 'Home',
    exact: true,
    footer: true,
    navigation: true,
    element: HomePage,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '//',
    title: '',
    exact: true,
    footer: true,
    navigation: true,
    element: HomePageRedirect,
    authenticatedRoute: false,
    layered: false,
  },
]

const subdomainRoutes = [
  {
    path: '/launch',
    title: 'Holoride campaign',
    exact: true,
    footer: true,
    navigation: true,
    element: HolorideCampaign,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/launch/modal/:modalName',
    title: 'Holoride campaign',
    exact: true,
    footer: true,
    navigation: true,
    element: HoloModals,
    authenticatedRoute: false,
    layered: true,
    preventDirectNavigation: true,
    redirectPath: '/launch',
  },
  {
    path: '/collections/:identifier/modal/:modalName',
    title: 'Collections',
    exact: true,
    footer: true,
    navigation: true,
    element: CollectionModals,
    authenticatedRoute: true,
    redirectPath: '/collections/:identifier',
    layered: true,
    adminSidebar: { actions: ['markCollectionNsfw'] },
  },
  {
    path: '/collections/:identifier',
    title: 'Collection',
    exact: true,
    footer: true,
    navigation: true,
    element: Collections,
    authenticatedRoute: false,
    layered: false,
    adminSidebar: { actions: ['markCollectionNsfw'] },
  },

  {
    path: '/ledger',
    title: 'Ledger',
    footer: true,
    navigation: true,
    element: Ledger,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/walletconnect',
    title: 'WalletConnect',
    footer: true,
    navigation: true,
    element: WalletConnect,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/nfts/:identifier/auction/:auctionId/modal/:modalName/:tab',
    title: 'Modals.Auction',
    exact: true,
    navigation: true,
    element: Auction,
    authenticatedRoute: true,
    redirectPath: '/nfts/:identifier/auction/:auctionId',
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
    preventDirectNavigation: true,
  },
  {
    path: '/nfts/:identifier/auction/:auctionId/modal/:modalName',
    title: 'Modals.Auction',
    exact: true,
    navigation: true,
    element: Auction,
    authenticatedRoute: true,
    redirectPath: '/nfts/:identifier/auction/:auctionId',
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
    preventDirectNavigation: true,
  },
  {
    path: '/nfts/:identifier/modal/:modalName/:tab',
    title: 'Modals.Auction',
    exact: true,
    navigation: true,
    element: Auction,
    authenticatedRoute: true,
    redirectPath: '/nfts/:identifier',
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
    preventDirectNavigation: true,
  },
  {
    path: '/nfts/:identifier/modal/:modalName',
    title: 'Modals.Auction',
    exact: true,
    navigation: true,
    element: Auction,
    authenticatedRoute: true,
    redirectPath: '/nfts/:identifier',
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
    preventDirectNavigation: true,
  },
  {
    path: '/nfts/:identifier/auction/:auctionId',
    title: 'TokenIdentifier',
    exact: true,
    footer: false,
    navigation: true,
    element: NftPage,
    authenticatedRoute: false,
    sidebarOnTop: true,
    bodyOverflow: true,
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
  },
  {
    path: '/nfts/:identifier',
    title: 'TokenIdentifier',
    exact: true,
    footer: false,
    navigation: true,
    element: NftPage,
    authenticatedRoute: false,
    sidebarOnTop: true,
    bodyOverflow: true,
    layered: true,
    adminSidebar: { actions: ['markNftNsfw'] },
  },
  {
    path: '/campaigns/:minterAddress/:campaignId',
    title: 'Campaign',
    exact: true,
    footer: true,
    element: Campaign,
    navigation: true,
  },
  {
    path: '/campaigns/:minterAddress/:campaignId/:tier/modal/:modalName',
    title: 'Campaign',
    exact: true,
    element: CampaignModals,
    navigation: true,
    authenticatedRoute: true,
    redirectPath: '/campaigns/:minterAddress/:campaignId',
    preventDirectNavigation: true,
  },
  {
    path: '/logout',
    title: 'Logout',
    exact: true,
    footer: true,
    navigation: true,
    element: Logout,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '/:address/modal/:modalName',
    title: 'Account',
    footer: true,
    navigation: true,
    element: SubdomainAccountModals,
    exact: true,
    authenticatedRoute: true,
    redirectPath: '/',
    layered: true,
  },
  {
    path: '/',
    title: 'Home',
    exact: true,
    footer: true,
    navigation: true,
    element: HomePageSubdomain,
    authenticatedRoute: false,
    layered: false,
  },
  {
    path: '//',
    title: '',
    exact: true,
    footer: true,
    navigation: true,
    element: HomePageRedirect,
    authenticatedRoute: false,
    layered: false,
  },
]

export const routes = env() === 'subdomain' ? subdomainRoutes : allRoutes
