import image from 'startup/assets/beacon-image.svg'
import { Button } from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

export const Master = () => <div className={'col-12 col-lg-4 d-flex mb-4 mb-md-4'}>
  <div className={'p-4 home-welcome-background '}>
    <img src={image} alt={'...'} className={'home-welcome-image'} />
    <p className={'home-welcome-title'}>Be a Beacon of Light</p>
    <p className={'home-welcome-description'}>
      You can now submit your project to be featured on xSpotlight.com! Before you do, however,
      please take a close look...
    </p>
    <Button to={'/master'} buttonType={'secondary'} className={'home-welcome-button'}>
      Read More
      {' '}
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  </div>
</div>
