import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useLocation, useParams } from 'react-router-dom'
import { useCloseLayerNavigate } from 'components/layered-router/hooks/use-close-layer-navigate'
import { useEffect } from 'react'

const matchAddresses = (addressOrHerotag, account) => {
  const accountHerotag = `@${account?.username?.split('.')[0]}`

  return addressOrHerotag === account?.address || addressOrHerotag === accountHerotag
}

export const useRedirectAccountModals = () => {
  const { address: addressOrHerotag } = useParams()
  const { account } = useGetAccountInfo()
  const { state } = useLocation()
  const closelayer = useCloseLayerNavigate()

  useEffect(() => {
    if (!matchAddresses(addressOrHerotag, account) && state?.hydrated) {
      closelayer()
    }
  }, [account, addressOrHerotag, closelayer, state?.hydrated])
}
