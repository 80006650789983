import { ButtonSecondary } from 'components/button-secondary'
import { useOnClick } from 'account/tabs/create-nft-button/hooks/use-on-click'
import { useIsActiveUser } from 'common/hooks/use-is-active-user'

export const CreateNftButton = () => {
  const isActiveUser = useIsActiveUser()
  const onClick = useOnClick()

  return isActiveUser ? <ButtonSecondary icon={'add'} onClick={onClick}> Create Nft </ButtonSecondary> : ''
}
