import { useResetState } from 'explore-nfts/behaviour/hooks/use-reset-state'
import { useFetchAssetTags } from 'tags/hooks/use-fetch-asset-tags'
import { useAppendSearchTag } from 'tags/hooks/use-append-search-tag'
import { useFetchStats as useFetchExploreStats } from 'explore-nfts/stats/hooks/use-fetch-stats'
import { useFetchStats as useFetchNftsStats } from 'explore-nfts/tabs/nfts/stats/hooks/use-fetch-stats'
import { useFetchPaymentTokens } from 'explore-nfts/current-payment-tokens/hooks/use-fetch-payment-tokens'

export const Behaviour = () => {
  useResetState()
  useFetchAssetTags()
  useAppendSearchTag('assets')
  useFetchExploreStats()
  useFetchNftsStats()
  useFetchPaymentTokens()

  return ''
}
