import './index.scss'
import { useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelectItem } from './hooks/use-select-item'
import { Button } from './button'
import { useSetSelected } from 'components/dropdown/hooks/use-set-selected'

const makeClassName = (active = '') => `dropdown-item ${active ? 'active' : ''}`

export const Dropdown = ({ options, onChange, id, disabled, name }) => {
  const [selected, setSelected] = useState({})
  const selectItem = useSelectItem(setSelected, onChange)
  useSetSelected({ setSelected, options, name })

  return <div className={'dropdown'}>
    <Button id={id} selected={selected} disabled={disabled} />
    <div className={'dropdown-menu'} aria-labelledby={id}>
      {options.map(option => <button key={option.name + option.value} onClick={() => selectItem(option)}
                                     disabled={disabled} className={makeClassName(selected.name === option.name)}>
        <div className={'d-flex justify-content-between'}>
          <div>{option.value}</div>
          <div>{selected.name === option.name && <FontAwesomeIcon icon={faCheck} />}</div>
        </div>
      </button>)}
    </div>
  </div>
}
