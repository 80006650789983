import { Button } from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import image from 'startup/assets/welcome-image.svg'

export const Kickstart = () => <>
  <div className={'col-12 col-lg-6 col-md-6'}>
    <p className={'home-welcome-highlight'}>HIGHLIGHT</p>
    <p className={'home-welcome-title'}>Welcome to xSpotlight</p>
    <p className={'home-welcome-description'}>
      Here you will find amazing content from across the MultiversX,
      created by people all over the world pushing the boundaries of creativity and technology.
    </p>
    <Button to={'/kickstart'} buttonType={'secondary'} className={'home-welcome-button'}>
      Read More
      {' '}
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  </div>
  <div className={'col-12 col-lg-6 col-md-6'}>
    <img src={image} alt={'...'} className={'home-welcome-image'} />
  </div>
</>
