import { NavLink } from 'react-router-dom'

const menuLink = ' nav-item text-center link-item'
const makeClassName = ({ isActive }) => `nav-link${isActive ? ' active' : ''}`

export const MenuLink = ({ link }) => <li className={menuLink}>
  <NavLink to={link.path} className={makeClassName} end>
    {link.label}
  </NavLink>
</li>

