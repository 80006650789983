import iconHome from 'startup/assets/icon-home.svg'
import iconVerified from 'startup/assets/icon-verified.svg'
import iconSearch from 'startup/assets/icon-search.svg'
import iconWorld from 'startup/assets/icon-world.svg'
import iconMegaphone from 'startup/assets/icon-megaphone.svg'

export const boxes = [
  {
    icon: iconHome,
    title: 'Decentralization',
    subtitle: <>
      Web3 aims to move away from centralized systems and towards a more decentralized network, where
      power is distributed among many nodes or users.
    </>,
  },
  {
    icon: iconVerified,
    title: 'Programmable money',
    subtitle: <>
      Web3 brings the concept of &quot;programmable money&quot; where you can automate monetary operations
      like dividends, royalties or voting and other contract-based features.
    </>,
  },
  {
    icon: iconSearch,
    title: 'Privacy and security',
    subtitle: <>
      Web3 focuses on giving users greater control over their personal data and improving the security
      of online transactions.
    </>,
  },
  {
    icon: iconWorld,
    title: 'Digital collectibles:',
    subtitle: <>
      Unique, limited digital items (art, music, videos, game items, etc) stored and traded on
      blockchain as NFTs, valuable for collectors and enthusiasts.
    </>,
  },
  {
    icon: iconMegaphone,
    title: 'Decentralized identity',
    subtitle: <>
      Web3 also aims to give users a secure way to prove their identity and access services,
      with an emphasis on self-sovereignty and privacy.
    </>,
  },
  {
    icon: iconMegaphone,
    title: 'Community ownership',
    subtitle: <>
      Within web3, a group of individuals can collectively own and govern digital assets/platforms
      through DAOs. DAOs allow for democratic decision-making, transparency, and fair distribution
      of profits.
    </>,
  },
]
