import PropTypes from 'prop-types'
import { Title } from 'components/home-section/title'
import { Description } from 'components/home-section/description'
import { ViewAll } from 'components/home-section/view-all'
import 'components/home-section/index.scss'

export const HomeSection = props => <div className={`home-section`}>
  <div className={'container'}>
    <div className={'home-section-header'}>
      <Title title={props.title} />
      <div className={'row'}>
        <div className={'col-12 col-lg-2'} />
        <div className={'col-12 col-lg-8 d-flex align-items-center'}>
          <Description description={props.description} />
        </div>
        <div className={'col-12 col-lg-2 d-flex align-items-center justify-content-lg-end justify-content-center'}>
          {props.showAllLink && <ViewAll showAllLink={props.showAllLink} showAllLinkText={props.showAllLinkText} />}
        </div>
      </div>
    </div>
  </div>
  {props.children}
</div>

HomeSection.defaultProps = {
  title: '',
  showAllLink: '',
  description: '',
}

HomeSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showAllLink: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
