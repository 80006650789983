import { Details } from 'nft-page/auction/details'
import { useOnClick } from 'auction/actions/place-bid/bid/hooks/use-on-click'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { WatchableAction } from '../watchable-action'
import { actionTypes } from 'action-statuses/actions-types'
import { get } from 'lodash'
import { useMinimum } from 'components/forms/place-bid-form/hooks/use-minimum'
import { denominateAmount } from 'common/conversion/denominate-amount'

export const PlaceBid = ({ auction, address }) => {
  const placeBidClick = useOnClick(auction)
  const onClick = usePermittedAction(placeBidClick, address)
  const minBidTokenSymbol = get(auction, 'minBid.tokenData.symbol')
  const minBidTokenDecimals = get(auction, 'minBid.tokenData.decimals')
  const amount = useMinimum(auction)
  const priceFormatted = denominateAmount(amount, minBidTokenDecimals, true)
  const price = `${priceFormatted} ${minBidTokenSymbol}`

  return <div className={'nft-page-auction-inner'}>
    <Details auction={auction} />
    <WatchableAction entity={auction} actionType={actionTypes.bid} text={'Bid'} onClick={onClick} price={price} />
  </div>
}

PlaceBid.displayName = 'PlaceBid'
