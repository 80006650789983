import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { actionTypes } from 'action-statuses/actions-types'
import { useStatus } from 'nft-page/auction/actions/watchable-action/hooks/use-status'
import { useWithdrawOffer } from 'offers/hooks/use-withdraw-offer'
import { useWithdrawOfferSelector } from 'offers/hooks/use-withdraw-offer-selector'
import { createActionIdentifier } from 'action-statuses/create-action-identifier'
import { useOnSentPayload } from 'modals/hooks/use-on-sent-payload'
import { withdraw } from 'offers/slices'
import { Button as AppButton } from 'components/button'
import { Errors } from 'components/forms/nerds-form'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

export const Withdraw = ({ offer }) => {
  const { address } = useGetAccountInfo()
  const offerId = parseInt(get(offer, 'id'))
  const withdrawOffer = useWithdrawOffer(offerId)
  const onClick = usePermittedAction(withdrawOffer, address)
  const status = useStatus(offer, actionTypes.withdrawOffer)
  const { data, errors } = useWithdrawOfferSelector()
  const route = useLocation()

  const actionIdentifier = createActionIdentifier(offer, actionTypes.withdrawOffer)

  useOnSentPayload({ data, callbackUrl: route, resetAction: withdraw.actions.reset, actionIdentifier, isModal: false })

  return <div className={'ms-3'}>
    <AppButton onClick={onClick} disabled={!!status}
               buttonType={'secondary'} className={'nft-page-offer-action'}>
      Withdraw
    </AppButton>
    <Errors values={errors} />
  </div>
}
