import { useNftDefaultAuction } from 'nft-page/hooks/nft/use-nft-default-auction'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { get } from 'lodash'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { useMarketplaceAddress } from 'marketplaces/hooks/use-marketplace-address'

export const useIsAuctionOwner = () => {
  const auction = useNftDefaultAuction()
  const { address } = useGetAccountInfo()
  const auctionOwnerAddress = get(auction, 'ownerAddress')

  return address === auctionOwnerAddress
}

const useIsMarketplaceOwner = () => {
  const { asset } = useAssetWithOwner()
  const assetOwnerAddress = get(asset, 'ownerAddress')
  const marketplaceAddress = useMarketplaceAddress()

  return assetOwnerAddress === marketplaceAddress
}

export const useIsAuctionContractOwner = () => {
  const auction = useNftDefaultAuction()
  const isAuctionOwner = useIsAuctionOwner()
  const isMarketplaceOwner = useIsMarketplaceOwner()

  return auction ? isAuctionOwner : isMarketplaceOwner
}
