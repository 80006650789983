import { shouldUseMinBid } from 'common/should-use-min-bid'
import metaspace from 'startup/assets/metaspace.svg'
import xoxno from 'startup/assets/xoxno.svg'
import frameit from 'startup/assets/frame-it.svg'
import deadrare from 'startup/assets/deadrare.svg'
import { Price } from 'nft-page/auction/price'
import { get } from 'lodash'
import 'nft-page/auction/current-bid/index.scss'

const priceIcon = {
  xoxno,
  frameit,
  deadrare,
}

export const CurrentBid = ({ auction }) => {
  const title = shouldUseMinBid(auction) ? 'Listing Price' : 'Highest Bid'
  const marketplaceKey = get(auction, 'marketplaceKey', '')
  const imageSource = priceIcon[marketplaceKey] || metaspace

  return <div className={'nft-current-bid'}>
    <p className={'nft-current-bid-title'}>{title}</p>
    <div className={'d-flex align-items-center'}>
      <img className={'nft-current-bid-image'} src={imageSource} alt={'...'} />
      <Price />
    </div>
  </div>
}
