import { FollowButton } from 'components/follow-button'
import { UnfollowButton } from 'components/unfollow-button'
import { useState } from 'react'
import { useOnClick } from 'explore-nfts/tabs/creators/hooks/use-on-click'

export const Action = ({ isFollowed, address }) => {
  const [isFollowedLocal, setIsFollowedLocal] = useState(isFollowed)
  const onClick = useOnClick(setIsFollowedLocal, address, isFollowedLocal)

  return isFollowedLocal ? <UnfollowButton onClick={onClick} /> : <FollowButton onClick={onClick} />
}
