import { useMemo } from 'react'
import { settings } from 'settings/fetching'
import { useLastCursor } from 'explore-nfts/tabs/creators/hooks/use-last-cursor'
import { useFilterValue } from 'filters/hooks/use-filter-value'

const useCursor = customCursor => {
  const endCursor = useLastCursor()

  return customCursor === undefined ? endCursor : customCursor
}

const sortFilter = {
  'most-active': 'MostActive',
  'most-followed': 'MostFollowed',
  trending: 'Trending',
}

export const useFetchPayload = customCursor => {
  const cursor = useCursor(customCursor)
  const sort = useFilterValue('sort')

  return useMemo(() => ({
    pagination: { first: settings().listRequestSize, after: cursor },
    filters: { sorting: sortFilter[sort] || 'MostActive' },
  }), [cursor, sort])
}
