import { SftForm } from 'modals/offers/accept/sft-form'
import { useList as useRunningAuctions } from 'nft-page/details/other-listings/hooks/use-list'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { get } from 'lodash'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

export const Sft = ({ offer }) => {
  const runningAuctions = useRunningAuctions()
  const filteredAuctions = runningAuctions.filter(({ node }) => node.orders?.edges?.length === 0)
  const extractedAuctions = filteredAuctions.map(({ node }) => node)
  const { asset, assetOwner } = useAssetWithOwner()
  const { address } = useGetAccountInfo()
  const supply = get(asset, 'supply')
  const balance = get(assetOwner, 'balance', '0')
  const offerQuantity = get(offer, 'quantity')
  const auctions = extractedAuctions.filter(item => {
    const hasTokens = item.nrAuctionedTokens === parseInt(offerQuantity)
    const isOwner = item.ownerAddress === address

    return hasTokens && isOwner
  })

  return offer && auctions ? <SftForm offer={offer} auctions={auctions} supply={supply} balance={balance} /> : ''
}
