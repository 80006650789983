import { formatAmount } from '@elrondnetwork/dapp-core/utils'
import { settings } from 'settings/fetching'

const digits = () => settings().dapp.decimals

const withCommas = (input, decimals) => formatAmount({
  input,
  decimals,
  addCommas: true,
  digits: digits(),
  showLastNonZeroDecimal: false,
})

const withoutCommas = (input, decimals) => formatAmount({ input, decimals })

export const denominateAmount = (input, decimals = 18, showCommas = false) => (
  showCommas ? withCommas(input, decimals) : withoutCommas(input, decimals)
)
