import { DropdownFilter } from 'filters/components/dropdown-filter'
import { useSetLocationFilters } from 'filters/hooks/use-set-location-filters'
import { useIsSortByDisabled } from 'filters/hooks/use-is-sort-by-disabled'

export const SortBy = ({ className, options, id = 'sort-filter' }) => {
  const setLocationFilters = useSetLocationFilters()
  const disabled = useIsSortByDisabled()

  return <DropdownFilter label={'Sort by'} options={options} id={id} disabled={disabled}
                         name={'sort'} onChange={option => {
                           !disabled && setLocationFilters({ name: 'sort', value: option.name })
                         }} className={className} />
}
