import { actionTypes } from 'action-statuses/actions-types'
import { WatchableAction } from 'nft-page/auction/actions/watchable-action'
import { useAssetWithOwner } from 'nft-page/auction/hooks/use-asset-with-owner'
import { usePermittedAction } from 'auction-flows/hooks/use-permitted-action'
import { useOnClickSendOffer } from 'nft-page/offers/actions/hooks/use-on-click-send-offer'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'

export const Send = () => {
  const { asset } = useAssetWithOwner()
  const sendOfferClick = useOnClickSendOffer(asset)
  const { address } = useGetAccountInfo()
  const onClick = usePermittedAction(sendOfferClick, address)

  return <div className={'nft-page-offers-make'}>
    <WatchableAction entity={asset} actionType={actionTypes.sendOffer} text={'Make Offer'} onClick={onClick} />
  </div>
}
