import { gql } from 'graphql-tag'

export const EXPLORE_COLLECTIONS = gql`
    query exploreCollections($filters: CollectionsFilter, $pagination: ConnectionArgs, $input: CollectionAssetsRetriveCount, $sorting: CollectionsSortingEnum) {
        collections(filters: $filters, pagination: $pagination, sorting: $sorting) {
            edges {
                node {
                    collection
                    name
                    type
                    canCreate
                    canBurn
                    canWipe
                    canPause
                    canAddQuantity
                    canTransferRole
                    ownerAddress
                    description
                    owner {
                        address
                        herotag
                        profile
                    }
                    artist{
                        address
                        herotag
                        profile
                    }
                    roles {
                        address
                        roles
                        canCreate
                    }
                    collectionAsset {
                        totalCount
                        assets (input: $input) {
                            identifier
                            thumbnailUrl
                        }
                    }
                    verified
                }
            }
            pageData {
                count
                offset
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`
