import { Behaviour } from 'home/marketplaces/behaviour'
import { HomeSection } from 'components/home-section'
import { useMarketplacesContent } from 'home/marketplaces/hooks/use-marketplaces-content'
import { MarketplacesCarousel } from 'components/marketplaces-carousel'

const description = `Join creators, collectors, and builders from all over the world, 
who are starting on this exciting journey.`

export const Marketplaces = () => {
  const items = useMarketplacesContent()

  return <>
    <Behaviour />
    <HomeSection title={'Ecosystem Marketplaces'} description={description}>
      <MarketplacesCarousel items={items} />
    </HomeSection>
  </>
}
