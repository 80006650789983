import { followers } from 'repositories/followers'
import { from, map, mergeMap, of, toArray } from 'rxjs'

const buildEdge = edge => followers.get.follows(edge.node)
  .pipe(mergeMap(response => from(response.json())))
  .pipe(map(response => ({ ...edge, node: { ...edge.node, isFollowed: response.follows } })))

export const getIsFollowed = isLoggedIn => ({ data: { artists } }) => {
  if (!isLoggedIn) {
    return of({ data: { artists } })
  }

  return from(artists.edges)
    .pipe(mergeMap(buildEdge))
    .pipe(toArray())
    .pipe(map(edges => ({ data: { artists: { ...artists, edges } } })))
}
