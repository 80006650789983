import { ReactComponent as FullXSpotlight } from 'startup/assets/icon-full-xspotlight.svg'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FeaturedTwitter = () => <div className={'container'}>
  <div className={'featured-twitter row align-items-center'}>
    <div className={'col-12 col-lg-6'}>
      <FullXSpotlight className={'featured-twitter-image'} />
    </div>
    <div className={'col-12 col-lg-6 '}>
      <p className={'featured-twitter-text'}>
        Join us as we explore the vast Multiverse of web3 projects to the Power of X, and reveal new ways of
        building meaningful and sustainable communities.
      </p>
      <a href={'https://twitter.com/elrondnetwork'} target={'_blank'} className={'btn-primary btn'} rel={'noreferrer'}>
        <FontAwesomeIcon icon={faTwitter} />
        {' '}
        Join us on Twitter
      </a>
    </div>
  </div>
</div>
