import { Kickstart } from 'home/welcome/kickstart'
import { Learn } from 'home/welcome/learn'
import { Create } from 'home/welcome/create'
import { Master } from 'home/welcome/master'
import './index.scss'

export const Welcome = () => <div className={'home-welcome container'}>
  <div className={'home-welcome-background'}>
    <div className={'row align-items-center m-0'}>
      <Kickstart />
    </div>
  </div>
  <div className={'row mt-4'}>
    <Learn />
    <Create />
    <Master />
  </div>
</div>
