import { useDispatch } from 'react-redux'
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { send } from 'offers/slices'
import { nominateAmount } from 'common/conversion/nominate-amount'
import moment from 'moment/moment'

export const useSendOffer = paymentToken => {
  const dispatch = useDispatch()
  const { address } = useGetAccountInfo()
  const { identifier } = useParams()

  return useCallback(payload => {
    const variables = {
      input: {
        identifier,
        deadline: moment(payload.deadline).unix()
          .toString(),
        paymentAmount: nominateAmount(payload.paymentAmount, paymentToken.decimals),
        paymentToken: paymentToken.identifier,
        paymentTokenNonce: paymentToken.nonce,
        quantity: `${payload.quantity}`,
        ...payload.auctionId ? { auctionId: parseInt(payload.auctionId) } : {},
      },
    }

    address && dispatch(send.actions.attempt(variables))
  }, [address, dispatch, identifier, paymentToken.decimals, paymentToken.identifier, paymentToken.nonce])
}
