import { useCurrentPrice } from 'components/asset-card/hooks/use-current-price'
import { ReactComponent as MetaspaceIcon } from 'startup/assets/metaspace.svg'
import { ReactComponent as XoxnoIcon } from 'startup/assets/xoxno.svg'
import { ReactComponent as Frameit } from 'startup/assets/frame-it.svg'
import { ReactComponent as Deadrare } from 'startup/assets/deadrare.svg'
import { getPriceDetails } from 'common/get-price-details'
import { get } from 'lodash'
import 'components/asset-card/price/price.scss'

const priceIcon = {
  xoxno: <XoxnoIcon />,
  frameit: <Frameit />,
  deadrare: <Deadrare />,
}

export const Price = ({ auction }) => {
  const bid = useCurrentPrice(auction)
  const { token, millyfiedAmount, usdAmount } = getPriceDetails(bid)
  const marketplaceKey = get(auction, 'marketplaceKey', '')
  const icon = priceIcon[marketplaceKey] || <MetaspaceIcon />

  return <div className={'asset-card-price justify-content-between'}>
    <div className={'asset-card-price-inner'}>
      {icon}
      <div className={'asset-card-prices'}>
        <div className={'asset-card-price-value'}>
          <b>{`${millyfiedAmount}`}</b>
          {` ${token}`}
        </div>
        {usdAmount && <div className={'asset-card-price-usd'}>{`$${usdAmount}`}</div>}
      </div>
    </div>
  </div>
}
